:root {
  --red-000: #2e1107;
  --red-100: #55170d;
  --red-200: #7e1a0f;
  --red-300: #aa1810;
  --red-400: #d41c14;
  --red-500: #e3513a;
  --red-600: #ef775e;
  --red-700: #f99a84;
  --red-800: #ffbcac;
  --red-900: #ffded6;
  --gold-000: #23170a;
  --gold-100: #3e2811;
  --gold-200: #5b3914;
  --gold-300: #794b16;
  --gold-400: #995e18;
  --gold-500: #ba7218;
  --gold-600: #dd8616;
  --gold-700: #f99e34;
  --gold-800: #ffbf7d;
  --gold-900: #ffe0c0;
  --green-000: #182c14;
  --green-100: #20411a;
  --green-200: #265820;
  --green-300: #2d6f25;
  --green-400: #32872a;
  --green-500: #389f2f;
  --green-600: #3cb934;
  --green-700: #41d339;
  --green-800: #8de47c;
  --green-900: #c9f2bd;
  --teal-000: #162a2a;
  --teal-100: #1b3d3d;
  --teal-200: #1f5251;
  --teal-300: #226766;
  --teal-400: #237c7c;
  --teal-500: #239393;
  --teal-600: #21aaaa;
  --teal-700: #1bc2c2;
  --teal-800: #56d7d6;
  --teal-900: #a2e7e6;
  --blue-000: #131a25;
  --blue-100: #1c2d45;
  --blue-200: #224267;
  --blue-300: #26578c;
  --blue-400: #286eb3;
  --blue-500: #2786db;
  --blue-600: #5b9ded;
  --blue-700: #8db4f2;
  --blue-800: #b6ccf6;
  --blue-900: #dbe5fb;
  --indigo-000: #1c1141;
  --indigo-100: #231683;
  --indigo-200: #1a18cb;
  --indigo-300: #3e2cf2;
  --indigo-400: #6d4bf5;
  --indigo-500: #8d69f8;
  --indigo-600: #a886fa;
  --indigo-700: #c1a4fc;
  --indigo-800: #d7c2fe;
  --indigo-900: #ebe0ff;
  --violet-000: #261423;
  --violet-100: #461d3f;
  --violet-200: #68265f;
  --violet-300: #8d2f80;
  --violet-400: #b336a3;
  --violet-500: #da3dc7;
  --violet-600: #f45ce0;
  --violet-700: #fb8be8;
  --violet-800: #feb4f0;
  --violet-900: #ffdaf8;
  --gray-000: #191a1b;
  --gray-100: #2b2d2f;
  --gray-200: #3e4145;
  --gray-300: #52565b;
  --gray-400: #676c73;
  --gray-500: #7d838c;
  --gray-600: #949ba4;
  --gray-700: #aeb3ba;
  --gray-800: #c8ccd1;
  --gray-900: #e3e5e8;
}
