.warehouse-list {
  margin-top: 7px;
  border: 1px solid #a7a7a7;
  border-radius: 7px;
  padding: 14px;
  height: 300px;
  overflow-y: scroll;
}

.warehouse-list__title {
  font-size: 16px;
  font-weight: bold;
  margin: 0px;
}

.warehouse-list__options {
  margin-top: 7px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4px;
}

.warehouse-list__radiobox {
  margin-right: 7px;
}
