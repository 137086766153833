/* NOTE: the order of these styles DO matter */

/*  Will edit everything selected including everything between a range of dates */
.CalendarDay__selected_span {
  background: #82e0aa;
  color: white;
  border: 1px solid $light-red;
}

/*  Will edit selected date or the endpoints of a range of dates */
.CalendarDay__selected {
  background: $dark-red;
  color: white;
}

/*  Will edit when hovered over. _span style also has this property */
.CalendarDay__selected:hover {
  background: orange;
  color: white;
}

/*  Will edit when the second date (end date) in a range of dates
    is not yet selected. Edits the dates between your mouse and said date */
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: brown;
}

.DateRangePicker {
  position: relative;
  display: inline-block;
}

.DateRangePicker_1 {
  z-index: 4;
  min-width: 274px;
}

.DateRangePickerInput__withBorder {
  border-bottom: 1px;
  border-bottom-style: solid;
  border-bottom-color: #dbdbdb;
  border-top: 0;
  border-left: 1px;
  border-right: 0;
}

.DateRangePickerInput__withBorder_2 {
  border-bottom: 0;
  border-top: 0;
  border-left: 1px;
  border-right: 0;
  padding-left: 0;
}

.DateInput_input {
  font-family: sans-serif;
  font-size: 1rem;
  font-weight: 0;
}

.DateInput_input_1 {
  border-right: 1px solid #dfe0e1;
  padding-left: 30px;
}

.body {
  line-height: 4.5rem;
}

.DateRangePickerInput_arrow_svg_1 {
  display: none;
}

.vertical-divider {
  border-right: 1px #dfe0e1 solid;
  border-bottom: none;
  margin: 0px;
  width: 10px;
  height: 50px;
  margin-right: 20px;
}
